const VueGallerySlideshow = () => import( 'vue-gallery-slideshow');

export default {
    name: 'gallery',
    components: {
        VueGallerySlideshow,
    },
    data() {
        return {
            carouselImages: [],
            index: null,
        }
    },
    created() {
        this.getCarouselImages();
    },
    methods: {
        getCarouselImages() {
            let url = "/getbackimages";
            this.$http().get(url)
                .then((data) => {
                    this.carouselImages = !data.data ? [] : data.data
                    if (!this.carouselImages) {
                        console.log('Could not get any background iamges to show');
                    }
                });
        },
    },
}
